// the layout of tables

.table {
  border-collapse: collapse;
  width: 100%;

  thead tr {
    border-bottom: 1px solid lighten($color-black, 80%);
  }

  thead td {
    font-size: 13px;
    padding: 0 15px 15px;
    text-transform: uppercase;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  tbody tr {
    border-bottom: 1px solid lighten($color-grey-light, 5);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: rgba($color-black, 0.05);
      cursor: pointer;
    }

    &.no-click:hover {
      background: inherit;
      cursor: default;
    }

    &.selected {
      background-color: lighten($color-grey-dark, 25);
      color: $color-white;
    }

    &.disabled {
      background-color: lighten($color-grey-light, 5);
      border-bottom: 1px solid lighten($color-black, 90%);
      color: darken($color-grey, 10);
      cursor: default;
    }
  }

  tbody td {
    font-size: 0.875rem;
    font-weight: 300;
    padding: 12px;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  tbody .btn {
    color: $color-accent;

    .icon {
      font-size: 24px;
    }

    &:hover {
      color: lighten($color-accent, 10);
    }
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }
}

.td-actions {
  white-space: nowrap;

  .btn {
    font-size: 1.6rem;
    margin: 0 6px;
  }
}

.table-footer {
  padding: 16px 0 32px;
}

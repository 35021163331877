// sass-lint:disable-all
// important needs to be used to overide the inline styles set by the wordcloud d3 directive
$hellocloud-hover: #ffb366;
$hellocloud-active: #f93;

hellocloud {
  text {
    &:hover {
      cursor: pointer;
      fill: $hellocloud-hover !important;
      opacity: 0.8;
    }

    &.active {
      fill: $hellocloud-active !important;
    }
  }
}

.question-templates-picker {
  &-close {
    cursor: pointer;
    font-size: 24px;
    height: 40px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    width: 40px;
    z-index: 1;
  }

  .filters {
    padding: 15px;
  }
}

.gl-setting {
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;

  .name {
    margin-bottom: 4px;
  }

  .toggle {
    display: inline-block;
    margin-left: 15px;
  }

  &.disabled {
    cursor: no-drop;
    pointer-events: none;
  }

  .tip {
    margin-left: 10px;
  }
}

.toggle {
  $color: $color-accent;

  background: rgba($color, 0.3);
  border-radius: 6px;
  height: 12px;
  position: relative;
  width: 30px;

  .toggle-thumb {
    background: $color;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgba($color-black, 0.24);
    height: 20px;
    position: absolute;
    right: -4px;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 20px;

    &.gi {
      color: rgba($color-white, 0.85);
      font-size: 15px;
      line-height: 25px;
      text-align: center;
    }
  }

  &.off {
    background: darken($color-white, 17%);

    .toggle-thumb {
      background: darken($color-white, 8%);
      right: 15px;
    }

    .gi {
      color: rgba($color-black, 0.54);
    }
  }

  .disabled & {
    background: darken($color-white, 70);

    .toggle-thumb {
      background: darken($color-white, 50);
    }
  }

  &::after {
    bottom: -20px;
    content: '';
    left: -20px;
    position: absolute;
    right: -20px;
    top: -20px;
  }
}

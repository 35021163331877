$header-height: 64px;
$header-height-mobile: 56px;

header {
  @include material-shadow(1);

  background-color: $header-bg;
  height: $header-height;
  line-height: $header-height - 20;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
  width: 100%;
  z-index: $z-index-header;

  @include respond-to('small') {
    height: $header-height-mobile;
    line-height: $header-height-mobile - 20;
  }

  .gi {
    color: $color-accent;
  }

  .nav-show-btn,
  .nav-min-btn {
    cursor: pointer;
    float: left;
    font-size: 1.4em;
    line-height: inherit;
    padding-left: 10px;
  }

  // show min nav button by default, hide on mobile
  .nav-min-btn {
    display: inline-block;

    @include respond-to('small') {
      display: none;
    }
  }

  // show show/hide nav button only on mobile/small
  .nav-show-btn {
    display: none;

    @include respond-to('small') {
      display: inline-block;
    }
  }

  .header-title {
    display: inline-block;
    font-size: 1.4em;
    line-height: inherit;
    margin: 0;
    max-width: 45%;
    overflow: hidden;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header-input {
    display: inline-block;
    vertical-align: top;

    input {
      height: 30px;
      margin-left: 10px;
      outline: none;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .header-actions,
  .header-loading {
    float: right;
    padding: 0 10px;
    text-align: right;
    width: 50%;
  }

  .header-loading {
    height: 55px;
    position: relative;

    gl-spinner {
      bottom: 10px;
      position: absolute;
      right: 14px;
    }

    .gl-spinner {
      height: 24px;
      width: 24px;
    }
  }
}

.external-panel {
  &__transform {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px;
    max-width: 700px;

    .label {
      margin-bottom: 2px;
    }

    > div {
      margin: 0 5px;
    }

    &-type,
    &-in,
    &-out,
    &-rid,
    &-hint,
    &-template {
      flex: 1;
    }

    &-checkbox {
      padding-top: 12px;
    }

    &-remove {
      flex: inherit;
      padding-top: 33px;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    margin: -11px 0 31px 0px;
  }
}

.gl-spinner {
  $offset: 187;
  $duration: 1.4s;

  animation: rotator $duration linear infinite;
  height: 30px;
  width: 30px;

  .gl-spinner-path {
    animation: dash $duration ease-in-out infinite;
    stroke: $color-accent; // default color
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;

    &.primary {
      stroke: $color-primary;
    }

    &.secondary {
      stroke: $color-accent;
    }

    &.white {
      stroke: $color-white;
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }

    50% {
      stroke-dashoffset: $offset / 4;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}

.button-spinner {
  margin: 6px 0;
}

.loading {
  margin: 0 auto;
  text-align: center;

  small {
    color: $color-accent;
    display: inline-block;
    line-height: 30px;
    margin-bottom: 16px;
    margin-left: 8px;
    vertical-align: middle;
  }
}

//
// Global Sass variables
//

// Global breakpoints
// ------------------------------------
$breakpoints: (
  'small': (
    max-width: 640px,
  ),
  'medium': (
    max-width: 1024px,
  ),
  'large': (
    max-width: 1400px,
  ),
  'xlarge': (
    max-width: 1920px,
  ) //'xxlarge',
);

// Fonts
// ------------------------------------
$base-font-family: 'Roboto', sans-serif;
$header-font-family: 'Roboto', sans-serif;

// Font Sizes
$base-font-size: 1em !default;
$h1-font-size: $base-font-size * 2.75 !default;
$h2-font-size: $base-font-size * 2.3 !default;
$h3-font-size: $base-font-size * 1.75 !default;
$h4-font-size: $base-font-size * 1.5 !default;
$h5-font-size: $base-font-size * 1.25 !default;
$h6-font-size: $base-font-size !default;

// Line height
$base-line-height: 1.5 !default;
$header-line-height: 1.25 !default;

// Other Sizes
$base-spacing: $base-line-height * 1em !default;

// Colours
// ------------------------------------
$color-glow-orange: #faa41a;
$color-accent: #009688;

$color-white: #fff;
$color-black: #000;
$color-red: #f00;

$color-amethyst: #ab47bc;
$color-boulder: #767676;
$color-eucalyptus: #209a4e;
$color-dodger-blue: #2196f3;

$color-grey-superlight: #f6f6f6;
$color-grey-light: #efefef;
$color-grey: #ccc;
$color-grey-medium: #777;
$color-grey-dark: #37474f;

$color-primary: #428bca !default;
$color-success: #5cb85c !default;
$color-info: #5bc0de !default;
$color-warning: #f0ad4e !default;
$color-danger: #d9534f !default;

// nps graph
$color-nps-pro: #2ca02c;
$color-nps-pas: #c6c6c6;
$color-nps-det: #d62728;

// Mood Colors
$color-mood-yellow: #f6d513;
$color-mood-green: #4cd764;
$color-mood-orange: #f90;
$color-mood-grey: #c8c8c8;
$color-mood-pink: #f25e5a;
$color-mood-red: #e20613;

$base-background-color: $color-white !default;
$base-font-color: rgba($color-black, 0.87);
$base-border-color: #ccc;
$base-link-color: $color-accent;

$font-highlight-color: $color-accent;

$hover-link-color: darken($base-link-color, 15);

// Easing Curves
// ------------------------------------
$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 0.5s !default;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
$swift-ease-in-out: all $swift-ease-in-out-duration
  $swift-ease-in-out-timing-function;

// z-index's
// ------------------------------------
$z-index-tooltip: 100 !default;
$z-index-dialog: 80 !default;
$z-index-sidenav: 60 !default;
$z-index-backdrop: 50 !default;
$z-index-dropdown: 30 !default;
$z-index-fab: 20 !default;
$z-index-header: 10 !default;

// Sidenav
// ------------------------------------
$sidenav-bg-color: #424242 !default;
$sidenav-default-width: 150px !default;
$sidenav-condensed-width: 100px !default;
$sidenav-ease-duration: 0.1s;

// Old variables
// ------------------------------------
$bg: #ddd;
$text-color: lighten($color-black, 30%);
$input-bg: #f0f0f0;
$nav-width: 200px;
$nav-bg: #424242;
$header-bg: $color-white;
$tooltip-bg: darken($nav-bg, 3%);
$action-bg: $color-accent;
$actions-panel-width: 200px;
$tab-section-bg: lighten($nav-bg, 5%); // #E7E7E7;
$tab-section-height: 120px;
$datepicker-bg: $tab-section-bg;

$tff-font-size: 1em; // 16px
// $tff-line-height:26px;
$tff-transition: all 0.15s $swift-ease-in-out-timing-function;
$tff-line-focused-width: 2px;
// label element (aka hint)
$tff-hint-offset-large: 22px;
$tff-hint-offset-small: 4px;
$tff-hint-offset-scale: 0.75;
// $tff-margin: 10px 0 (10px - $tff-line-focused-width) 0;
// line element (disbaled)
$tff-line-dot-width: 1px;
$tff-line-dot-size: 2px;
$tff-line-dashed-color: #cfcfcf;

// Mixins
// --------------------------------
@mixin text-field($transition: false, $border-width: 1px) {
  input,
  textarea {
    border-bottom-width: $border-width;

    @if ($transition != false) {
      transition: $transition;
    }
  }
}

@mixin text-hint($size: large, $transition: false) {
  label {
    @if ($size == 'small') {
      transform: translate(0, $tff-hint-offset-small)
        scale($tff-hint-offset-scale);
    } @else {
      transform: translate(0, $tff-hint-offset-large);
      transform-origin: left center;
      transition: $tff-transition;
    }

    @if ($transition != false) {
      transition: $transition;
    }
  }
}

@mixin text-dashed-line($dot-gap, $dot-size) {
  background-size: $dot-size $dot-gap;
  background-position: 0 bottom;
  // background-size: (1px + 1px) $dot-gap;
  background-repeat: repeat-x;
}

gl-input {
  // sass-lint:disable-line
  @include text-hint(large, $tff-transition);
  // @include text-field($tff-transition);
  display: block;
  // margin: $tff-margin;
  min-height: 80px;
  padding-bottom: $tff-line-focused-width;
  position: relative;

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'],
  input[type='color'] {
    // border-width: 0 0 1px;
    // line-height: $tff-line-height;
    // padding-bottom: 1px;
    // padding-top: 2px;
    // border-color: $base-border-color;
    border: 0;
    border-bottom: 1px solid $base-border-color;
    display: block;

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px $color-white inset;
    }

    &:focus {
      outline: 0;
    }

    // Firefox fix
    &:required {
      box-shadow: none;
    }
  }

  &.gl-input-focused {
    @include text-hint(small);
    // @include text-field(false, $tff-line-focused-width);

    // input {
    //     padding-bottom: 0;
    // }

    label {
      color: $font-highlight-color;
      // padding: 8px 0 4px;
    }
  }

  &.gl-input-has-value {
    @include text-hint(small);

    // &:not(.gl-input-has-value) {
    //     @include text-hint(small);
    // }
  }

  &:focus {
    outline: 0;
  }

  input,
  textarea {
    background: none;
    padding-bottom: 4px;
    // padding: 4px 0;
    width: 100%;
  }

  label {
    color: darken($base-border-color, 20);
    display: block;
    font-size: $tff-font-size;
    // padding: 12px 0 0;
    pointer-events: none;
    z-index: 1;
  }

  &[disabled] {
    @include text-hint(small);

    *:not(.gl-input-has-value) {
      @include text-hint(large);
    }

    input,
    textarea {
      // @include text-dashed-line($tff-line-dot-width, $tff-line-dot-size);
      pointer-events: none;
    }
  }

  .gl-input-bar {
    display: block;
    position: relative;
  }

  .gl-input-bar::before,
  .gl-input-bar::after {
    background: $font-highlight-color;
    bottom: 0;
    content: '';
    height: 2px;
    position: absolute;
    transition: $tff-transition;
    width: 0;
  }

  .gl-input-bar.gl-input-error::before,
  .gl-input-bar.gl-input-error::after {
    background-color: $color-red;
    width: 50%;
  }

  .gl-input-bar::before {
    left: 50%;
  }

  .gl-input-bar::after {
    right: 50%;
  }

  input:focus ~ .gl-input-bar::before,
  input:focus ~ .gl-input-bar::after,
  textarea:focus ~ .gl-input-bar::before,
  textarea:focus ~ .gl-input-bar::after {
    width: 50%;
  }

  gl-input-error,
  .input-error {
    color: $color-red;
    display: block;
    font-size: 0.75em;
    margin: 0;
  }
}

// outside the scope as used by select and others
.gl-input-hint {
  color: $color-grey;
  display: block;
  font-size: 0.75em;
  margin: 0;
}

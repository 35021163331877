gl-content {
  @include hardware-accel;

  display: block;
  margin-left: $sidenav-default-width;
  position: relative;
  transition: margin-left $sidenav-ease-duration
    $swift-ease-in-out-timing-function;

  // always 100% width on mobile
  @include respond-to('small') {
    margin-left: 0;
  }

  // expand/collapse with sidenav on > small devices
  &.gl-expanded {
    margin-left: $sidenav-condensed-width;
  }
}

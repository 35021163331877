.post {
  @include clearfix();
  @include material-shadow(1);

  background-color: $color-white;
  border-bottom: 1px solid $color-grey-light;
  font-size: 0.875rem; // 14px
  margin: 0 auto;
  max-width: 940px;
  position: relative;

  .icon {
    left: 16px;
    position: absolute;
    top: 0;

    .gi-circle {
      font-size: 1.3rem;
      height: 28px;
      line-height: 28px;
      margin-top: 6px;
      position: relative;
      width: 28px;
    }
  }
}

.post-header {
  border-bottom: 1px solid $color-grey-light;
  cursor: pointer;
  min-height: 48px;
  padding: 10px 128px 0 64px;
  position: relative;

  .date,
  p {
    line-height: 28px;
    margin: 0;
  }

  .date {
    color: rgba($color-black, 0.56);
    position: absolute;
    right: 16px;
    top: 10px;
  }
}

.post-access-url {
  font-size: 12px;
  margin-bottom: 9px;
}

.post-content {
  font-weight: 300;
  padding: 16px 64px;
  position: relative;

  &::before,
  &::after {
    color: rgba($color-black, 0.16);
    font-family: Arial, Tahoma, sans-serif;
    font-size: 3.5em;
    line-height: 1;
    position: absolute;
  }

  &::before {
    content: '\201C';
    left: 24px;
    top: 8px;
  }

  &::after {
    bottom: 8px;
    content: '\201D';
    line-height: 0;
    right: 24px;
  }

  p {
    margin: 0;
  }

  .icon .gi {
    margin-top: -10px;
  }
}

.post-image {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.post-tags {
  padding: 8px 32px;
}

.post-tag {
  display: inline-block;
  min-width: 250px;
  padding: 0 16px 16px 0;
  position: relative;
  vertical-align: top;

  .label {
    font-size: 0.8125rem; // 13px
    line-height: 1.3;
    overflow: hidden;
    padding: 4px 0 0 36px;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;

    .nps-break {
      border-left: 1px solid rgba($color-black, 0.24);
      margin-left: 5px;
      padding-left: 5px;
    }
  }

  .gi {
    font-size: 1.8rem;
    left: 0;
    position: absolute;
    top: -10px;
  }
}

.comment,
.reply-wrapper {
  .icon .gi-circle {
    height: 32px;
    line-height: 32px;
    margin-top: 0;
    width: 32px;
  }
}

.comment {
  border-top: 1px solid $color-grey-light;
  min-height: 48px;
  padding: 8px 16px 16px 64px;
  position: relative;

  .icon {
    left: 16px;
    position: absolute;
    top: 8px;

    .gi-circle {
      background-color: rgba($color-black, 0.24);
      font-size: 1.05rem;
    }

    .admin {
      background-color: $color-glow-orange;
    }

    .phone {
      background-color: $color-dodger-blue;
    }

    .subscriber {
      background-color: $color-eucalyptus;
    }
  }

  .content {
    font-weight: 300;
  }

  .comment-header {
    position: relative;

    .name {
      padding-right: 80px;
      word-break: break-word;
    }

    .date {
      color: rgba($color-black, 0.26);
      font-size: 0.8125rem;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.comment-actions,
.post-actions {
  @include clearfix();

  font-size: 0.8125rem;
  margin-top: 4px;

  .hidden {
    color: $color-danger;
    margin-right: 16px;
  }

  button {
    @extend %base-btn;

    color: $color-dodger-blue;
    float: right;

    &:hover {
      color: darken($color-dodger-blue, 10);
    }
  }
}

.post-actions {
  font-size: 1rem;
  margin-bottom: 16px;
  padding: 0 16px;
}

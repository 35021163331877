// core page scaffolding
.main {
  max-width: 1200px;
  padding-bottom: 1px;
  position: relative;

  &.large {
    max-width: 1300px;
  }

  &.has-actions {
    padding-right: $actions-panel-width + 30;
  }

  .inner {
    padding: 30px 30px 0;
  }
}

.actions {
  border-radius: 3px;
  color: $color-white;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 30px;
  width: $actions-panel-width;

  .action-section {
    border-radius: 3px;
    margin-bottom: 20px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .action {
    background: $action-bg;
    border-bottom: 1px solid lighten($action-bg, 3%);
    display: block;
    font-family: 'Montserrat', sans-serif;
    padding: 15px 20px;
    text-align: center;

    &-subtext {
      font-size: 11px;
      line-height: 1;
    }

    &:hover {
      background: lighten($action-bg, 3%);
      cursor: pointer;
    }
  }
}

.box {
  background: $color-white;
  border-radius: 3px;
  margin-bottom: 30px;
  padding-bottom: 10px;

  &.horizontal-scroll {
    overflow-x: auto;
  }
}

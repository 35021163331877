@import './dialog/content-groups-selector-dialog';

.content-groups-selector {
  max-width: 700px; // hack
  margin: 0 0 20px;

  &__head {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
  }

  &__title {
    flex-grow: 1;
  }

  &__zero {
    font-size: 12px;
    color: rgba($color-black, 0.4);
  }

  &__content-group {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0 0 4px;
    background: rgba($color-black, 0.05);
  }

  &__content-group-name {
    flex-grow: 1;
  }
}

//
// Glow-icons icon font styles
//

.gi {
  display: inline-block;
  height: 100%;

  &::before {
    line-height: inherit;
    margin-left: 1px;
  }

  // &.purple {
  //     color: $color-glow-purple;
  // }

  &.blue {
    color: $color-dodger-blue;
  }

  &.green {
    color: $color-eucalyptus;
  }
}

.gi-circle,
%gi-circle {
  background-color: $color-glow-orange;
  border-radius: 50%;
  color: $color-white;
  font-size: 1.875rem;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  top: 4px;
  width: 40px;

  // &.purple {
  //     background-color: $color-glow-purple;
  // }

  &.blue {
    background-color: $color-dodger-blue;
  }

  &.green {
    background-color: $color-eucalyptus;
  }
}

.gi-circle-dark {
  @extend %gi-circle;

  background-color: $color-grey-dark;
}

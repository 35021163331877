.manage-kits {
  &__kits {
    max-width: 700px; // hack
  }

  &__kits-head {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
  }

  &__kits-title {
    flex-grow: 1;
  }

  &__kit-zero {
    font-size: 12px;
    color: rgba($color-black, 0.4);
  }

  &__kit {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0 0 4px;
    background: rgba($color-black, 0.05);
  }

  &__kit-name {
    flex-grow: 1;
  }
}

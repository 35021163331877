$breadcrumb-bg: #f5f5f5 !default;
$breadcrumb-color: #e9e9e9 !default;
$breadcrumb-active-color: #999 !default;

// Textual separator for between breadcrumb elements
$breadcrumb-separator: '/' !default;

.breadcrumbs {
  background-color: $breadcrumb-bg;
  color: $color-boulder;
  font-size: 0.875rem;
  list-style: none;
  padding: 11px 16px;

  > li {
    display: inline-block;

    .gi {
      font-size: 1.2em;
      position: relative;
      top: 3px;
    }

    a {
      color: $color-boulder;
    }

    + li::before {
      color: $breadcrumb-color;
      content: '#{$breadcrumb-separator}\00a0'; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
    }

    .active {
      color: $breadcrumb-active-color;
    }
  }
}

// panels in the main ui view
.gl-content-panel-wrapper {
  margin: 16px 8px;
}

.gl-content-panel {
  @include clearfix();
  @include material-shadow(1);

  @media (max-width: 1592px) {
    max-width: 840px;
  }

  background-color: $color-white;
  font-size: 0.875rem;
  margin: 0 auto;
  max-width: 66%;
  padding: 15px;
  position: relative;
}

.gl-panel-group-header {
  @media (max-width: 1592px) {
    max-width: 840px;
  }

  color: $color-grey-medium;
  line-height: 40px;
  margin: 0 auto;
  max-width: 66%;
  padding-left: 16px;
}

// for the imageinput directive

.image-input {
  background: $input-bg;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  .icon {
    color: $color-white;
    cursor: pointer;
    height: 25px;
    line-height: 25px;
    position: absolute;
    right: 0;
    text-align: center;
    text-shadow: 0 1px 5px rgba($color-black, 0.3);
    top: 0;
    width: 25px;
  }

  .size {
    bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    height: 14px;
    left: 0;
    line-height: 1;
    margin: auto;
    opacity: 0.5;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }

  input {
    -webkit-appearance: none;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .image {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

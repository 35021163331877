.add-kit-dialog {
  &__head {
    display: flex;
    align-items: center;
    padding: 16px;
  }

  &__title {
    flex-grow: 1;
  }
}

$button-disabled-color: rgba($color-black, 0.26);
$button-disabled-background-color: none;

$button-primary-color: $color-accent;
$button-primary-background-color: inherit;

$button-border-radius: 2px;
$button-line-height: 25px;
$button-padding: 2px 6px 3px;

// Fab buttons
$button-fab-width: 56px;
$button-fab-height: 56px;
$button-fab-padding: 16px;

// Action buttons
$action-button-size: 45px;

// FAB button positioning
@mixin fab-position(
  $spot,
  $top: auto,
  $right: auto,
  $bottom: auto,
  $left: auto
) {
  &.gl-fab-#{$spot} {
    bottom: $bottom;
    left: $left;
    position: fixed;
    right: $right;
    top: $top;
  }
}

%base-btn,
[type='submit'] {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.gl-button,
%gl-button {
  @extend %base-btn;

  border-radius: $button-border-radius;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  margin: 6px 4px;
  min-width: 64px;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: box-shadow $swift-ease-in-out-duration
      $swift-ease-in-out-timing-function,
    background-color $swift-ease-in-out-duration
      $swift-ease-in-out-timing-function;
  // transform $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
  user-select: none;
  white-space: nowrap;

  // don't transition the above when hiding the element
  &.ng-hide-add {
    transition: none;
  }

  &:hover,
  &:focus {
    background-color: rgba(153, 153, 153, 0.2);
    outline: none;
  }

  &:active {
    background-color: rgba(153, 153, 153, 0.4);
  }

  &::after {
    bottom: -6px;
    content: '';
    left: -4px;
    position: absolute;
    right: -4px;
    top: -6px;
  }

  &[disabled] {
    background: $button-disabled-background-color;
    color: $button-disabled-color;
    cursor: not-allowed;
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &.right {
    float: right;
  }

  &.primary {
    background-color: $button-primary-background-color;
    color: $button-primary-color;
  }

  .icon {
    font-size: 1.2em;
    margin-right: 10px;
  }

  &.show-spinner {
    background: transparent;
    color: transparent;

    .gl-spinner {
      opacity: 1;
    }
  }
}

.gl-fab {
  @extend %base-btn;

  @include material-shadow(1);
  @include fab-position(
    bottom-right,
    auto,
    ($button-fab-width - $button-fab-padding) / 2,
    ($button-fab-height - $button-fab-padding) / 2,
    auto
  );
  @include fab-position(
    bottom-left,
    auto,
    auto,
    ($button-fab-height - $button-fab-padding) / 2,
    ($button-fab-width - $button-fab-padding) / 2
  );
  @include fab-position(
    top-right,
    ($button-fab-height - $button-fab-padding) / 2,
    ($button-fab-width - $button-fab-padding) / 2,
    auto,
    auto
  );
  @include fab-position(
    top-left,
    ($button-fab-height - $button-fab-padding) / 2,
    auto,
    auto,
    ($button-fab-width - $button-fab-padding) / 2
  );

  background-clip: padding-box;
  background-color: $color-accent;
  border-radius: 50%;
  color: $color-white;
  font-size: 24px;
  height: 56px;
  line-height: 56px;
  min-width: 0;
  text-align: center;
  width: 56px;
  z-index: $z-index-fab;

  &:hover {
    background-color: lighten($color-accent, 5);
  }

  &:active {
    box-shadow: none;
  }

  &.mini {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  &[disabled] {
    background: $button-disabled-background-color;
    color: $button-disabled-color;
    cursor: not-allowed;
  }
}

.gl-button-raised {
  @extend %gl-button;

  &:not([disabled]) {
    @include material-shadow(1);
  }

  &:hover {
    background: transparent;
  }

  &:active {
    @include material-shadow(2);
  }

  &.primary {
    background-color: $color-accent;
    color: $color-white;
  }

  &[disabled] {
    background: $button-disabled-background-color;
    color: $button-disabled-color;
    cursor: not-allowed;
  }
}

.gl-btn {
  display: inline-block;
  position: relative;

  .gl-btn-box {
    transition: transform 0.2s ease-in-out;
  }

  .gl-spinner {
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
  }

  &.show-spinner {
    color: transparent;

    .gl-spinner {
      opacity: 1;
    }

    .gl-btn-box {
      opacity: 0;
      transform: scale(1.1);
    }
  }
}

.action-btn {
  @extend %base-btn;

  border: 1px solid transparent;
  border-radius: 50%;
  color: $color-accent;
  font-size: 1.875rem;
  height: $action-button-size - 2;
  line-height: $action-button-size - 2;
  text-align: center;
  width: $action-button-size - 2;

  &[disabled] {
    background: $button-disabled-background-color;
    color: $button-disabled-color;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: rgba($color-white, 0.15);
  }
}

.button-group {
  @include clearfix();
  margin-top: 10px;
}

gl-sidenav {
  @include hardware-accel;
  @include material-shadow(1);

  background-color: $sidenav-bg-color;
  bottom: 0;
  float: left;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 56px; // to ensure right spacing
  top: 0;
  transition: width $sidenav-ease-duration $swift-ease-in-out-timing-function;
  width: $sidenav-default-width;
  z-index: $z-index-sidenav;

  // show/hide nav on mobile
  @include respond-to('small') {
    position: fixed; // fixed so can scroll even when body overflow set to hidden
    transform: translate(-100%, 0);
    transition: all $swift-ease-in-out-duration
      $swift-ease-in-out-timing-function;

    &.gl-nav-open {
      transform: translate(0%, 0);
    }
  }

  // collapse/expand nav on > small devices
  &.gl-nav-min {
    width: $sidenav-condensed-width;
  }
}

// disallow scrolling - for when sidenav is open
// assuming small will always be mobile / phablet
body {
  .no-scroll {
    @include respond-to('small') {
      overflow: hidden;
    }
  }
}

.sidenav-header {
  padding: 24px;

  img {
    @include material-shadow(1);

    border-radius: 50%;
    display: block;
    height: 80px;
    margin: 0 auto;
    transition: height $sidenav-ease-duration $swift-ease-in-out-timing-function,
      width $sidenav-ease-duration $swift-ease-in-out-timing-function;
    width: 80px;

    .gl-nav-min & {
      height: 50px;
      width: 50px;
    }
  }

  .text {
    color: rgb(117, 117, 117);
    font-size: 0.75em;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.sidenav-list-item {
  cursor: pointer;
  padding: 16px;
  user-select: none;

  .text,
  .icon {
    color: rgb(117, 117, 117);
    text-align: center;
  }

  .icon {
    display: block;
    font-size: 30px;
    line-height: 1em;
    width: 100%;
  }

  .gl-nav-min & {
    .text {
      display: none;
    }

    .icon {
      font-size: 20px;
    }
  }

  &:hover:not(.disabled) {
    .icon {
      color: $color-white;
    }

    .text {
      color: $color-white;
    }
  }

  &.active {
    background-color: rgba($color-black, 0.1);
    border-left: 3px solid $color-accent;

    .icon {
      color: $color-white;
    }

    .text {
      color: $color-white;
    }
  }
}

$single-line-list-elem-height: 48px;
$double-line-list-elem-height: 72px;

.icon-list {
  @include clearfix();

  font-size: 0.875em;
  padding: 8px 0;
}

.list-subheader {
  color: rgba($color-black, 0.54);
  height: 40px;
  padding: 8px 0;
}

.list-break {
  border-top: 1px solid rgba($color-white, 0.12);
  width: 100%;
}

.icon-list-item,
%icon-list-item {
  @include clearfix();

  font-size: 1rem;
  height: $single-line-list-elem-height;
  line-height: $single-line-list-elem-height;
  padding: 0 16px;

  // fix for ng-if within list item to keep layout
  > div {
    overflow: auto;
  }

  &.disabled {
    opacity: 0.26;
  }

  &.clickable {
    cursor: pointer;
  }

  .double-line & {
    height: $double-line-list-elem-height;
  }

  .multi-line & {
    line-height: 1.4em;
    margin: 20px 0;
    min-height: 48px;
    position: relative;

    &:first-child,
    &:last-child {
      margin-top: 10px;
    }
  }

  .icon {
    color: rgba($color-black, 0.54);
    float: left;
    font-size: 1.875rem;
    line-height: $single-line-list-elem-height;
    width: 56px;

    .double-line & {
      line-height: $double-line-list-elem-height;
    }

    .multi-line & {
      line-height: 48px;
      margin-top: -24px;
      position: absolute;
      top: 50%;
      width: 15%;
    }
  }

  .text,
  .sub-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text {
    color: rgba($color-black, 0.87);

    .double-line & {
      line-height: 1.3rem;
      padding-top: 16px;
    }

    .multi-line & {
      float: right;
      font-size: 0.875rem;
      margin-top: 3px;
      overflow: auto;
      text-overflow: string;
      white-space: normal;
      width: 85%;
    }
  }

  .sub-text,
  .secondary-text {
    color: rgba($color-black, 0.54);
    font-size: 0.875rem;
    line-height: 1.2rem;
  }
}

.list-radio,
.list-radio-icon {
  @include clearfix();
  background-color: transparent;
  cursor: pointer;
  min-height: 38px;
  position: relative;

  &.disabled:hover {
    cursor: default;
  }

  &.multi-control {
    .label {
      width: 65%;

      @include respond-to('small') {
        width: 55%;
      }
    }

    .controls {
      width: 20%;

      @include respond-to('small') {
        width: 30%;
      }
    }
  }

  .icon {
    float: left;
    width: 15%;

    .gi {
      background-color: $color-grey-medium;
      top: 0;
    }
  }

  .label {
    float: left;
    line-height: 40px;
    width: 70%;

    @include respond-to('small') {
      font-size: 0.9em;
      padding-left: 10px;
    }

    .title,
    .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title {
      line-height: 25px;
    }

    .sub-title {
      color: lighten($base-font-color, 10);
      font-size: 0.75rem;
      line-height: 15px;
    }
  }

  .controls {
    float: right;
    text-align: right;
    width: 15%;
  }

  .registered-mark {
    color: $color-grey;
    font-size: 0.8rem;
    line-height: 20px;
    margin-top: -10px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 50%;

    .gi {
      display: inline-block;
      font-size: 1.5em;
      margin-right: 5px;
      position: relative;
      top: 4px;
    }
  }
}

.question-template {
  &-tabs {
    margin: 40px 0 10px;
  }

  &-tab {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: 0 5px 5px 0;
    padding: 0 15px;
    vertical-align: top;

    &.selected {
      background: $color-accent;
      border: 1px solid transparent;
      color: $color-white;
    }

    &.errors {
      background: $color-danger;
      border: 1px solid transparent;
      color: $color-white;
    }
  }

  &-editorWrapper {
    margin-top: 50px;
    max-width: 700px;
  }

  &-actions {
    margin: 0 0 40px;
  }

  &-state {
    font-size: 15px;
    font-weight: 500;
    margin: 0 10px 0 0;
  }

  &-tagTextarea {
    height: 50px;
  }
}

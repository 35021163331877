.add-hosting-credit-dialog {
  &__dialog {
    max-width: 420px;
  }

  &__content {
    padding: 20px 24px 24px;

    p {
      margin-top: 0;
      font-size: 18px;
    }
  }

  &__error {
    margin-top: 20px;
  }
}

.gl-backdrop-wrapper {
  left: 0;
  position: absolute;
  top: 0;
}

.gl-sidenav-backdrop {
  z-index: $z-index-sidenav - 1;
}

.gl-dialog-backdrop {
  z-index: $z-index-dialog - 1;
}

.gl-backdrop {
  background-color: rgba($color-black, 0.26);
  height: 100%;
  opacity: 1;
  position: fixed;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active,
  &.ng-leave {
    opacity: 1;
  }
}

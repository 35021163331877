// form components such as input, textarea, checkbox, radio

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.input {
  background-color: $input-bg;
  border-radius: 3px;
  margin-bottom: 20px;
  max-width: 700px;
  overflow: hidden;
  position: relative;

  &.select {
    background: darken($input-bg, 5%);

    .icon {
      font-size: 18px;
      height: 44px;
      left: inherit;
      line-height: 44px;
      pointer-events: none;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 44px;
    }
  }

  select {
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: $text-color;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 44px;
    outline: none;
    padding: 0 20px;
    width: 100%;
  }

  select::-ms-expand {
    display: none;
  }

  [type='email'],
  [type='password'],
  [type='text'],
  [type='number'],
  [type='tel'],
  textarea {
    background: none;
    border: 0;
    color: $text-color;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 44px;
    outline: none;
    padding: 0 15px;
    width: 100%;

    &.transparent {
      background: none;
    }

    &:disabled {
      color: lighten($text-color, 20);
    }
  }

  textarea {
    height: 120px;
    padding: 15px;
    resize: none;

    &.elastic-input {
      height: 44px;
    }
  }

  &.input-icon input {
    padding-left: 50px;
  }

  .icon {
    font-size: 20px;
    line-height: 44px;
  }

  > .icon {
    bottom: 0;
    left: 15px;
    position: absolute;
    top: 0;
  }

  ::-webkit-input-placeholder {
    color: rgba($color-black, 0.3);
    -webkit-font-smoothing: antialiased;
  }

  .btns-right {
    bottom: 0;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    border: 0;
    border-left: 1px solid $color-white;
    color: lighten($color-black, 40%);
    display: inline-block;
    font-size: 10px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    width: 44px;

    &:hover {
      color: lighten($color-black, 20%);
      cursor: pointer;
    }
  }

  &.filters {
    margin-bottom: 20px;
    max-width: none;
    width: 100%;

    .filters-label {
      display: inline-block;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
    }

    [type='text'] {
      border: 1px solid $color-grey;
      display: inline-block;
      height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      max-width: 150px;
      padding-left: 5px;
      padding-right: 5px;
    }

    select {
      -webkit-appearance: menulist;
      border: 1px solid $color-grey;
      display: inline-block;
      height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 5px;
      padding-right: 5px;
      width: auto;
    }

    button {
      background: none repeat scroll 0% 0% $color-grey-dark;
      border: 0;
      color: $color-white;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: middle;
    }

    .toggle {
      cursor: pointer;
      margin: 0 15px;
    }

    [disabled='disabled'] {
      color: $color-grey;
    }
  }
}

.input-group {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px 20px;
  max-width: 700px;

  &__child {
    margin: 0 5px;
    flex: 1;
  }
}

.radio {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  margin-bottom: 30px;
  padding: 0 30px 0 40px;
  position: relative;

  .circle {
    border: 1px solid $input-bg;
    border-radius: 50%;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
  }

  &:hover {
    cursor: pointer;

    .circle {
      border: 1px solid darken($input-bg, 10%);
    }
  }

  &.selected {
    .circle {
      border: 1px solid darken($input-bg, 10%);
    }

    .circle::after {
      background: darken($input-bg, 50%);
      border-radius: 50%;
      bottom: 9px;
      content: '';
      left: 9px;
      position: absolute;
      right: 9px;
      top: 9px;
    }
  }
}

.input-prefix {
  > :nth-child(1) {
    color: rgba($color-black, 0.26);
    float: left;
    font-size: 12px;
    font-weight: 500;
    line-height: 44px;
    padding: 0 0 0 15px;
  }

  > :nth-child(2) {
    overflow: hidden;
  }
}

.checkbox {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  margin-bottom: 30px;
  padding: 0 30px 0 40px;
  position: relative;

  .square {
    background-color: $input-bg;
    border-radius: 3px;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
  }

  .icon-checkmark {
    bottom: 0;
    color: $color-white;
    display: none;
    left: 0;
    line-height: 25px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }

  &:hover {
    cursor: pointer;

    .square {
      background-color: darken($input-bg, 10%);
    }
  }

  &.checked {
    .square {
      background-color: $color-red;
    }

    .icon-checkmark {
      display: block;
    }
  }
}

.label {
  margin-bottom: 20px;
  max-width: 700px;
  position: relative;

  &.large span {
    font-size: 16px;
  }

  .right {
    position: absolute;
    right: 0;
    top: -5px;

    .checkbox {
      margin-left: 30px;
      padding-right: 0;
    }
  }

  .buttons {
    position: absolute;
    right: 0;
    top: -4px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    background: lighten($header-bg, 20%);
    border-radius: 3px;
    color: $color-white;
    display: inline-block;
    font-size: 11px;
    height: 25px;
    line-height: 25px;
    margin: -2px 0 0 10px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;

    &:hover {
      background: $header-bg;
      cursor: pointer;
    }
  }

  [class^='icon-']::after,
  [class*=' icon-']::after {
    bottom: -10px;
    content: '';
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
  }

  .gi-info {
    color: lighten($text-color, 40%);
    display: inline-block;
    font-size: 20px;
    margin: 0 0 0 5px;
    vertical-align: middle;

    &:hover {
      color: $tooltip-bg;
      cursor: pointer;
    }
  }
}

.sublabel {
  margin: -16px 0 20px;
  font-size: 12px;
  color: rgba($color-black, 0.4);
}

.error-message {
  color: $color-red;
  display: block;
  margin: 0;
}

.warning-message {
  color: $color-warning;
  display: block;
}

// displays metrical stats with a name

.stats {
  .stat {
    background: darken($color-white, 5%);
    padding: 20px;
    text-align: center;

    .total {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}

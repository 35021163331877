.reply-wrapper {
  background-color: $color-grey-superlight;
  border-top: 1px solid $color-grey-light;
  overflow: hidden;
  padding: 16px 104px 16px 64px;
  position: relative;

  textarea {
    background: none;
    border: 0;
    font-weight: 300;
    min-height: 48px; // avoid height change when button shows
    outline: none;
    resize: none;
    width: 100%;
  }

  .reply-actions {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .icon {
    top: 10px;
  }

  .gi-circle {
    background-color: $color-grey;
  }

  &.active {
    .gi-circle {
      background-color: $color-glow-orange;
    }
  }

  .closed-text {
    color: rgba($color-black, 0.56);
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }
}

.comment-spinner {
  animation: rotate 0.8s infinite linear;
  border: 2px solid darken($color-glow-orange, 10);
  border-radius: 50%;
  border-right-color: transparent;
  height: 34px;
  left: -51px;
  margin: 50px;
  position: absolute;
  top: -51px;
  width: 34px;

  // prevent animation lag when removing from the DOM cause spinner to continue to show
  &.ng-leave,
  &.ng-leave-active {
    animation: none;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

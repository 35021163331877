body {
  background-color: $base-background-color;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: normal;
  line-height: $header-line-height;
  margin: 0;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
  line-height: 1.3;
  margin-bottom: 20px;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 ($base-spacing / 2);

  small {
    color: rgba($color-black, 0.46);
    font-size: 0.8rem;
  }
}

a {
  color: $base-link-color;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s linear;

  &:hover {
    color: $hover-link-color;
  }

  &:active,
  &:focus {
    color: $hover-link-color;
    outline: none;
  }

  &.disabled {
    color: darken($color-white, 20);
    cursor: default;

    &:hover {
      color: darken($color-white, 20);
    }
  }
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $base-spacing / 2;
    padding-left: $base-spacing;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $base-spacing / 2;
    padding-left: $base-spacing;
  }
}

dl {
  margin-bottom: $base-spacing / 2;

  dt {
    font-weight: bold;
    margin-top: $base-spacing / 2;
  }

  dd {
    margin: 0;
  }
}

pre {
  white-space: pre-wrap;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

blockquote {
  // border-left: 2px solid $base-border-color;
  color: lighten($base-font-color, 15);
  margin: $base-spacing 0;
  padding-left: $base-spacing / 2;
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// positions the dialog for scrolling containers
.gl-dialog-wrapper {
  left: 0;
  position: absolute;
  top: 0;
}

// sets the container to the content window not the browser window
.gl-dialog-container {
  height: 100%;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: $z-index-dialog;
}

gl-dialog {
  @include material-shadow(2);

  background-color: $color-white;
  display: block;
  margin: 5% auto;
  max-width: 80%;
  min-width: 240px;
  opacity: 0;
  overflow: hidden; // stop content from leaking out of dialog parent
  padding-bottom: 8px;
  position: relative;

  &.transition-in {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
    transition: $swift-ease-out;
  }

  &.transition-out {
    transform: translate3d(0, 100%, 0) scale(0.2);
    transition: $swift-ease-out;
  }

  .gl-dialog-content {
    padding: 24px 24px 16px;

    h5 {
      margin-bottom: 16px;
    }

    a {
      word-wrap: break-word;
    }
  }

  .gl-dialog-search-bar {
    @include clearfix();
    background-color: rgba(0, 0, 0, 0.05);
    height: 60px;

    .search-form {
      display: block;
      height: 100%;
      padding: 8px 32px;
    }

    .search-box {
      background-color: $color-white;
      border-radius: 2px;
      float: left;
      height: 100%;
      // margin: 0 auto;
      position: relative;
      text-align: left;
      width: 88%;
    }

    .go {
      float: right;
      height: 100%;
      line-height: 100%;
      margin: 0;
      min-width: 0;
      width: 10%;
    }

    input {
      background-color: transparent;
      border: 0;
      float: left;
      // height: 40px;
      margin: 10px 0;
      outline: none;
      padding: 0;
      width: 75%;
    }

    .gi {
      @extend %base-btn;

      background-color: transparent;
      line-height: 44px;
      padding: 0;
    }

    .gi-search {
      float: left;
      font-size: 1.875em;
      width: 15%;
    }

    .gi-close {
      float: right;
      font-size: 1.4em;
      width: 10%;
    }
  }

  .gl-dialog-search-content {
    padding: 16px 25px;

    @include respond-to('small') {
      padding: 16px 20px;
    }

    &.list {
      min-height: 200px;
      position: relative;
    }
  }

  .gl-dialog-actions {
    @include clearfix();

    height: 48px;
    padding: 0 16px;
    text-align: right;

    &.pagination {
      text-align: left;
    }
  }

  &.alert-dialog {
    max-width: 300px;

    p {
      margin: 10px 0 0;
      white-space: pre-wrap;
    }
  }

  &.small-dialog {
    max-width: 400px;
  }

  &.medium-dialog {
    max-width: 560px;
  }

  &.large-dialog {
    max-width: 748px;
  }
}

.add-credit {
  padding: 20px;

  &__dialog {
    max-width: 300px;
  }

  &__error {
    margin-top: 20px;
  }
}

.interaction-zero {
  color: $color-grey-medium;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.interaction-group {
  margin-top: 0;
}

.interaction {
  @include clearfix();
  @include material-shadow(1);

  @media (max-width: 1592px) {
    max-width: 840px;
  }

  background-color: $color-white;
  border-bottom: 1px solid $color-grey-light;
  font-size: 0.875rem; // 14px
  margin: 0 auto;
  max-width: 66%;
  position: relative;
  transition: max-width 100ms $swift-ease-in-out-timing-function,
    margin 100ms $swift-ease-in-out-timing-function;

  .collapse-wrapper {
    transition: height 150ms $swift-ease-in-out-timing-function;
  }

  .icon {
    bottom: 0;
    font-size: 1.875rem;
    height: 28px;
    left: 16px;
    line-height: 28px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 28px;
  }

  &:last-child {
    border-bottom: 0;
  }

  &.expand {
    @include material-shadow(2);

    @media (max-width: 1592px) {
      max-width: 872px;
    }

    margin-bottom: 16px;
    margin-top: 16px;
    max-width: 68%;
  }
}

.interaction-title-bar {
  height: 48px;
  padding: 10px 80px 0 64px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .from,
  .summary {
    float: left;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .from {
    font-weight: bold;
    width: 20%;

    .read & {
      font-weight: normal;
    }
  }

  .summary {
    width: 78%;

    .gi {
      color: rgba($color-black, 0.26);
      font-size: 1.8rem;
      margin-left: -5px;
      margin-right: 4px;
      vertical-align: top;
    }
  }

  .time {
    color: rgba($color-black, 0.26);
    line-height: 48px;
    position: absolute;
    right: 16px;
    top: 0;
  }

  .interaction-action {
    color: $color-glow-orange;
    display: none;
    float: right;
    font-size: 3em;
    line-height: 48px;

    &:hover {
      color: lighten($color-glow-orange, 10);
    }

    .expand & {
      display: inline-block;
    }
  }

  .read & {
    .icon {
      opacity: 0.56;
    }
  }
}

.interaction-header {
  border-bottom: 1px solid $color-grey-light;
  cursor: pointer;
  min-height: 48px;
  padding: 10px 194px 0 64px;
  position: relative;

  .date,
  p {
    line-height: 28px;
    margin: 0;
  }

  .date {
    color: rgba($color-black, 0.56);
    position: absolute;
    right: 16px;
    top: 10px;
  }

  .reward {
    font-size: 2.6rem;
    margin-right: -5px;
    text-align: right;
    vertical-align: middle;

    &:hover {
      color: $color-glow-orange;
    }
  }
}

// SEND COUPON DIALOG
// ---------------
.send-coupon-dialog {
  max-width: 550px;

  .tip {
    color: rgba($color-black, 0.28);
    font-size: 0.875rem;
    text-align: center;
  }
}

// SURVEY
// ---------------
.survey-questions {
  counter-reset: steps;
  padding: 0 16px;

  li {
    margin: 20px 15px;
    padding-left: 40px;
    position: relative;
  }

  li::before {
    color: rgba($color-black, 0.24);
    content: counter(steps);
    counter-increment: steps;
    font-size: 1.5rem;
    left: 0;
    position: absolute;
    top: 0;
  }

  .question-text {
    color: rgba($color-black, 0.64);
    font-size: 0.875em;
    margin: 0;
  }

  .survey-response-image {
    max-height: 120px;
    padding: 12px 0 0;
  }
}

// FEEDBACK
// ---------------
.post-content {
  padding: 16px 64px;
  position: relative;

  &::before,
  &::after {
    color: rgba($color-black, 0.16);
    font-family: Arial, Tahoma, sans-serif;
    font-size: 3.5em;
    line-height: 1;
    position: absolute;
  }

  &::before {
    content: '\201C';
    left: 24px;
    top: 8px;
  }

  &::after {
    bottom: 8px;
    content: '\201D';
    line-height: 0;
    right: 24px;
  }

  p {
    margin: 0;
  }

  .icon .gi {
    margin-top: -10px;
  }
}

.post-image {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.post-tags {
  padding: 8px 32px;
}

.post-tag {
  display: inline-block;
  min-width: 250px;
  padding: 0 16px 16px 0;
  position: relative;
  vertical-align: top;

  .label {
    font-size: 0.8125rem; // 13px
    line-height: 1.3;
    overflow: hidden;
    padding: 4px 0 0 36px;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .gi {
    font-size: 1.8rem;
    left: 0;
    position: absolute;
    top: -10px;
  }
}

.post-actions {
  @include clearfix();

  .gi {
    float: right;
    margin: 0 16px 8px;
  }
}

.comment,
.post-actions {
  .flag {
    color: rgba($color-black, 0.26);
    cursor: pointer;
    font-size: 1.1rem;
    height: 20px;

    &.highlight {
      color: $color-danger;
    }
  }
}

.comment,
.reply-wrapper {
  .icon .gi-circle {
    height: 32px;
    line-height: 32px;
    margin-top: 0;
    width: 32px;
  }
}

.comment {
  border-top: 1px solid $color-grey-light;
  min-height: 48px;
  padding: 8px 16px 16px 64px;
  position: relative;

  .icon {
    left: 16px;
    margin: 0;
    position: absolute;
    top: 8px;

    .gi-circle {
      background-color: rgba($color-black, 0.24);
      font-size: 1.05rem;
    }

    .a {
      background-color: $color-glow-orange;
    }

    .u {
      background-color: $color-dodger-blue;
    }

    .s {
      background-color: $color-eucalyptus;
    }
  }

  .header {
    position: relative;

    .name {
      font-weight: bold;
      padding-right: 80px;
      word-break: break-word;
    }

    .date {
      color: rgba($color-black, 0.26);
      font-size: 0.8125rem;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .flag {
    bottom: 16px;
    position: absolute;
    right: 16px;
  }
}

$checkbox-default-color: #ccc !default;
$checkbox-highlight-color: #009688 !default;

.gl-checkbox-label,
.gl-radio-label {
  cursor: pointer;
  display: block;

  &.disabled,
  .disabled & {
    cursor: default;
    opacity: 0.3;
  }
}

.gl-checkbox,
.gl-radio {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin: 0.5rem;
  margin-right: 1.375em;
  position: relative;
  top: 0.6rem;

  &:focus {
    outline: none;

    &::before {
      border-color: $font-highlight-color;
    }
  }

  &::before {
    border: 2px solid $checkbox-default-color;
    content: '';
    height: 1rem;
    left: 0;
    position: absolute;
    transition: $swift-ease-in-out;
    width: 1rem;
    z-index: 1;
  }

  &:disabled {
    &::before {
      border: 0;
    }
  }

  &:checked {
    &::before {
      border-color: $checkbox-highlight-color;
      border-right-style: none;
      border-top-style: none;
      height: 0.5rem;
      transform: rotate(-45deg);
    }
  }

  &::after {
    bottom: -6px;
    content: '';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }
}

.gl-radio::before {
  border-radius: 50%;
}

.gl-radio:checked::before {
  border-radius: 0;
}

.radio-inline {
  label {
    display: inline-block;
    margin: 0 8px;
    padding: 5px 0 10px;
    text-align: center;

    .gl-radio {
      top: -0.3rem;
    }
  }
}

// the login state styling

.login {
  background: $color-grey-superlight;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  form {
    @include material-shadow();

    background-color: $color-white;
    margin: 50px auto 0;
    max-width: 300px;
    padding: 20px 20px 1px;
  }
}

// common styles used around the app

hr {
  border: 0;
  border-bottom: 1px solid $base-border-color;
  margin: 20px 0;
}

.inset {
  padding-left: 30px;
  padding-right: 30px;
}

.inset-top {
  padding-top: 30px;

  h1 {
    text-transform: capitalize;
  }
}

.align-center {
  text-align: center;
}

.double {
  max-width: 700px;

  > div {
    display: inline-block;
    width: 50%;

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }
  }
}

.split-3 {
  > div {
    display: inline-block;
    margin-bottom: 20px;
    padding-right: 20px;
    width: 33.3333333%;
  }
}

.survey {
  &-question {
    position: relative;
  }

  &-question-admin-lock {
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.7);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 120px 20px 20px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
  }

  &-question-admin-lock-content {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-width: 330px;
    padding: 20px;
  }
}

// overrides for the ngQuickDate plugin

// not sure why this is here and not one of the variables used
$quickdate-bg-color: #f0f0f0;

.quickdate {
  background: $input-bg;
  border-radius: 3px;
  display: block;
  margin-bottom: 20px;

  > a {
    color: $text-color;
    display: block;
    height: 44px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
  }
}

.quickdate-button {
  background: $quickdate-bg-color;
  border: 0;
}

.credit-transactions-exporter {
  &__dialog {
    max-width: 700px;
  }

  &__section {
    margin: 10px 0 0;
    padding: 10px 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__section-actions {
    margin: 10px 0 0;

    g-btn {
      margin: 0 10px 0 0;
    }
  }

  &__calendars {
    display: flex;
    justify-content: space-around;
  }

  &__calendar-label {
    text-align: center;
    font-weight: 500;
    margin: 0 0 10px;
  }
}

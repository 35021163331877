.tabs {
  padding: 0 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab,
  .add {
    @include border-radiuses(3px, 3px, 0, 0);
    background: lighten($tab-section-bg, 5%);
    color: $color-white;
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    height: 44px;
    line-height: 44px;
    margin-right: 1px;
    margin-top: 1px;
    padding: 0 20px;

    &:hover {
      background: lighten($tab-section-bg, 10%);
      cursor: pointer;
    }

    &.selected {
      background: $bg;
      color: $text-color;
    }

    a {
      color: $tab-section-bg;
      position: relative;
      transition: none;
    }

    a:hover {
      background: lighten($tab-section-bg, 20%);
      color: $color-white;
    }
  }

  .add {
    background: darken($tab-section-bg, 2%);

    .icon {
      display: inline-block;
      font-size: 20px;
      line-height: 44px;
      margin-right: 5px;
      vertical-align: middle;
    }

    &:hover {
      background: darken($tab-section-bg, 4%);
    }
  }
}

.usage {
  &__dialog {
    max-width: 700px;
  }

  &__section {
    margin: 10px 0 0;
    padding: 10px 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__section-actions {
    margin: 20px 0 0;

    g-btn {
      margin: 0 10px 0 0;
    }
  }

  &__section-previous {
    margin: 20px 0;
  }

  &__section-display {
    margin: 40px 0;
  }

  &__section-display-empty {
    font-style: italic;
    text-align: center;
    margin: 10px 0;
  }

  &__calendars {
    display: flex;
    justify-content: space-around;
  }

  &__calendar-label {
    text-align: center;
    font-weight: 500;
    margin: 0 0 10px;
  }

  &__management_section {
    margin: 20px 0;
    section {
      margin: 20px 0;
    }
  }

  &__manage-row {
    display: flex;
    align-items: center;

    &.-credit {
      field-text {
        flex-grow: 1;
      }
    }

    &.-line-item {
      field-select {
        width: 50%;
      }

      field-text {
        width: 50%;
        margin-left: 5px;
      }
    }

    g-btn {
      margin-left: 5px;
    }
  }

  &__manage-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__process-btn {
    margin-left: 10px;
  }

  &__table-btn {
    display: inline-block;
  }
}

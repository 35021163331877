.dashboard {
  &__logz {
    width: 100%;
    height: calc(100% - 64px); // subtract height of header
    overflow: hidden;

    &-iframe {
      background: $color-white;
      border: 0;
      outline: 0;
      width: 100%;
      height: 100%;
      padding: 10px 0 10px 10px;
    }
  }
}

// apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
}

.app-view,
gl-content,
.portal-container {
  height: 100%;
}

.main-content {
  height: calc(100% - 70px); // subtract height of header
  overflow: auto;
  position: relative;

  section {
    padding: 16px;
  }

  &.dark {
    background-color: $color-grey-light;
  }
}

// think about this one...
.details,
.filters,
.list {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.pull-right {
  float: right;
}

// FIX: AP displays these weird
.gl-form-checkbox-group {
  align-items: center;
}

.gl-checkbox {
  height: 17px;
  margin: 0 10px 0 0 !important;
  position: static !important;
  top: inherit !important;
  width: 17px;
}
